import { showNotification } from '@mantine/notifications';
import React from 'react';
import { SEND_INVITE } from '../../services/companies';
import { GET_ALL_PROFILES } from '../../services/profiles';
import { GET_ALL_TEAMS } from '../../services/teams';
import { GET_USER_DATA, UPDATE_USER } from '../../services/users';
import InputField, { InputFieldTypes } from '../input_field';
import ModalForm from '../modal_form';
import { Button, Group, Modal, Stack } from '@mantine/core';

export default function Create({ open = true, onClose, onSave, id = null }) {
    const [formData, setFormData] = React.useState<any>(null);
    const [loadingData, setLoadingData] = React.useState(false);
    const [loadingSave, setLoadingSave] = React.useState(false);
    const [error, setError] = React.useState<any>(null);
    const [profile, setProfile] = React.useState<any[]>([]);
    const [teams, setTeams] = React.useState<any[]>([]);

    const loadProfile = () => {
        GET_ALL_PROFILES()
            .then(res => {
                setProfile(res)
            })
            .catch(err => {
                showNotification({ message: err.message })
            })
    }

    const loadTeams = () => {
        GET_ALL_TEAMS()
            .then(res => {
                setTeams(res)
            })
            .catch(err => { })
    }

    const onConfirm = () => {
        try {
            setError(null)
            setLoadingSave(true)
            if (id) {
                UPDATE_USER(id, { ...formData })
                    .then(res => {
                        setLoadingSave(false)
                        onSave && onSave()
                        showNotification({ title: "Atualização", message: `Usuário atualizado com sucesso!` })
                    })
                    .catch(err => {
                        setLoadingSave(false)
                        setError(err.message)
                    })
            } else {
                if (!formData?.email) throw new Error("Informe o e-mail do usuário!!");
                SEND_INVITE({ ...formData })
                    .then(res => {
                        setLoadingSave(false)
                        onSave && onSave()
                        showNotification({ title: "Novo Usuário", message: `Foi enviado um convite para o e-mail ${formData?.email}` })
                    })
                    .catch(err => {
                        setLoadingSave(false)
                        setError(err.message)
                    })
            }
        } catch (err) {
            setLoadingSave(false)
            showNotification({ title: "Ops", message: err.message, color: 'red' })
        }
    }

    const COLUMNS = [
        id ? null : { title: "Nome *", name: 'name', type: InputFieldTypes.STRING },
        id ? null : { title: "E-mail *", name: 'email', type: InputFieldTypes.STRING },
        id
            ? {
                title: "Perfil *",
                name: 'profile',
                fieldType: InputFieldTypes.SELECT,
                options: profile.map(p => ({ label: p.title, value: p._id }))
            }
            : {
                title: "Perfil *",
                name: 'profile',
                fieldType: InputFieldTypes.SELECT,
                options: profile.map(p => ({ label: p.title, value: p._id }))
            },
        {
            title: "Equipes",
            name: 'teams',
            multiple: true,
            fieldType: InputFieldTypes.SELECT,
            options: teams.map(p => ({ label: p.title, value: p._id }))
        },
    ].filter(nn => nn)

    React.useEffect(() => {
        loadProfile();
        loadTeams();
    }, [])

    React.useEffect(() => {
        if (id) {
            setLoadingData(true)
            GET_USER_DATA(id)
                .then(({ teams, user, settings }) => {
                    setLoadingData(false)
                    setFormData({
                        teams,
                        role: user.role,
                        settings,
                        profile: user.role?.profile?._id,
                    })
                })
                .catch(err => {
                    setLoadingData(false)
                    showNotification({ title: "Ops", message: err.message, color: 'red' })
                })
        } else {
            setFormData({});
        }
    }, [id]);

    return <Modal
        opened={open}
        onClose={onClose}
        centered
        size="xl"
        title={id ? "Editar usuário" : "Inserir usuário"}
    >
        <Stack>
            {COLUMNS.map(column => <InputField mb="md"
                onChange={(dt) => setFormData(fd => ({ ...fd, ...dt }))}
                value={(formData && formData[column.name]) ? formData[column.name] : null}
                {...column}
            />)}
            <InputField
                mb="lg"
                name="approve_block"
                title="Bloquear permissão de aprovar conteúdos"
                value={formData?.settings?.approve_block ?? false}
                fieldType={InputFieldTypes.SWITCH}
                onChange={({ }) => setFormData(fd => ({ ...fd, settings: { ...fd.settings, approve_block: !fd?.settings?.approve_block } }))}
            />
            {error && <Group style={{ color: "#FF9a9a", textAlign: 'right' }}>{error}</Group>}
            <Group position="center">
                <Button style={{ width: '30%' }}
                    onClick={onConfirm}
                    disabled={loadingSave}
                    loading={loadingSave}
                >Salvar</Button>
            </Group>
        </Stack>

    </Modal>
}