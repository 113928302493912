import { ActionIcon, Divider, Group, Menu, Paper, Text, Title } from '@mantine/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/auth.context';
import Table from '../components/table';
import { showNotification } from '@mantine/notifications';
import { DELETE_FILES, GET_ALL_FILES } from '../services/files';
import { formatFileSize } from '../utility/util';
import { FaEllipsisV, FaEye } from 'react-icons/fa';
import { useApp } from '../contexts/app.context';

export default function OverviewPage() {
    const { user: currentUser, role } = useAuth();
    const { confirmDialog } = useApp();

    let [items, setItems] = useState([]);
    const [loading, setLoading] = React.useState(false);

    const loadFiles = () => {
        setLoading(true);

        GET_ALL_FILES()
            .then(res => {
                setItems(res);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                showNotification({ message: err.message, color: 'red' });
            })
    }

    useEffect(loadFiles, [])

    return <>
        <Paper p="md" shadow='xs'>
            <Title order={4} color="lime" align='right'>Total: {formatFileSize(items.reduce((pv, r) => (pv + (isNaN(r.size) ? 0 : r.size)), 0))}</Title>
            <Table
                loading={loading}
                data={items}
                columns={[
                    { title: "Arquivo", key: "original_name" },
                    { title: "Tamanho", key: "size", render: item => formatFileSize(item.size) },
                ]}
                actions={[
                    {
                        title: "Visualizar",
                        onClick: ({ item }) => window.open(item.url, "_blank")
                    },
                    {
                        title: "Deletar", color: "red", onClick: ({ item }) => {
                            confirmDialog(
                                { text: "Realmente deseja deletar esse arquivo?" },
                                (({ confirmed }) => {
                                    if (confirmed) {
                                        const previous = items;
                                        setItems(its => its.filter(i => i._id !== item._id))
                                        DELETE_FILES([item._id])
                                            .then(() => { })
                                            .catch((err: any) => {
                                                setItems(previous);
                                                showNotification({ message: err.message })
                                            })
                                    }
                                })
                            )
                        }
                    },
                ]}
            />
        </Paper>
    </>
}
