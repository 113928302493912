import { api } from "./api"

export const GET_ALL_JOBS = async () => {
    const {data} = await api.get(`/jobs/all`)
    return data;
}

export const GET_JOB_DETAILS = async (jobId: string) => {
    const {data} = await api.get(`/jobs/${jobId}/details`)
    return data;
}

export const GET_JOB_STATUS_OPTIONS = async () => {
    const {data} = await api.get(`/jobs/status/options`)
    return data;
}

export const DELETE_JOB = async (jobId) => {
    const {data} = await api.delete(`/jobs/${jobId}`)
    return data;
}

export const ARCHIVE_JOB = async (jobId, archive = true) => {
    const {data} = await api.patch(`/jobs/${jobId}/archive`, { archive })
    return data;
}

export const MARK_JOB_VIEW = async (jobId, approver) => {
    const {data} = await api.patch(`/jobs/${jobId}/view`, { approver })
    return data;
}

export const SAVE_JOB = async (params) => {
    const {data} = await api.post(`/jobs/save`, params)
    return data;
}

export const REQUEST_JOB = async (params) => {
    const {data} = await api.post(`/jobs/request`, params)
    return data;
}

export const SAVE_JOB_STATUS = async (jobId: string, statusId: string) => {
    const {data} = await api.patch(`/jobs/${jobId}/status/${statusId}`)
    return data;
}

export const APPROVE_TASK = async (taskId: string, approver) => {
    const {data} = await api.patch(`/jobs/tasks/${taskId}/approve`, {approver})
    return data;
}

export const APPROVE_JOB = async (jobId: string, approver) => {
    const {data} = await api.patch(`/jobs/${jobId}/approve`, {approver})
    return data;
}

export const ADJUSTMENT_JOB = async (jobId: string, text, approver) => {
    const {data} = await api.patch(`/jobs/${jobId}/adjustment`, { text, approver })
    return data;
}

export const CLONE_JOB = async (jobId: string) => {
    const {data} = await api.patch(`/jobs/${jobId}/clone`)
    return data;
}

export const ADJUSTMENT_TASK = async (taskId: string, params) => {
    const {data} = await api.patch(`/jobs/tasks/${taskId}/adjustment`, params)
    return data;
}

export const PUBLISH_TASK = async (taskId: string) => {
    const {data} = await api.patch(`/jobs/tasks/${taskId}/publish`)
    return data;
}

export const DOWNLOAD_TASK = async (taskId: string) => {
    const {data} = await api.patch(`/jobs/tasks/${taskId}/download`, { }, { responseType: "arraybuffer" })
    return data;
}

export const PUBLIC_SAVE_TASK = async (taskId: string, approver, params) => {
    const {data} = await api.patch(`/jobs/tasks/${taskId}/save`, {...params, approver})
    return data;
}

export const SAVE_JOB_TASK = async (params) => {
    const {data} = await api.post(`/jobs/tasks/save`, params)
    return data;
}
