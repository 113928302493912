import {
    ActionIcon,
    Avatar,
    Box, Button,
    Grid, Group,
    Menu,
    Modal,
    Paper, Progress, Text, Title,
    Tooltip
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { Buffer } from 'buffer';
import { default as React, useEffect, useRef, useState } from 'react';
import { FaArrowLeft, FaArrowRight, FaCheck, FaClock, FaDrawPolygon, FaEdit, FaEye, FaFileDownload, FaMicrophone, FaQuoteLeft, FaShare, FaTasks, FaTrash, FaVolumeMute } from 'react-icons/fa';
import { FaExclamation } from 'react-icons/fa6';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useRecorder } from '../components/audio_recorder';
import { CanvasDrawer } from '../components/canvas_drawer';
import InputField, { InputFieldTypes } from '../components/input_field';
import RenderImage from '../components/render_image';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { useDownloadFiles } from '../hooks/useDownloadFiles';
import { ADJUSTMENT_TASK, APPROVE_JOB, APPROVE_TASK, DOWNLOAD_TASK, GET_JOB_DETAILS, MARK_JOB_VIEW, PUBLIC_SAVE_TASK } from '../services/jobs';
import { formatMilliseconds, getExtenseDatetime, getMedia, markdown } from '../utility/util';
import { JobHistory } from './job';

export default function PublicJobPage(props) {
    const { jobId: jobIdParams } = useParams()
    const jobId = props.jobId || jobIdParams;
    const navigate = useNavigate()
    const app = useApp()
    const { role, userData } = useAuth();
    const { loading: loadingDownloadFiles, download: downloadFiles } = useDownloadFiles();
    const [searchParams, setSearchParams] = useSearchParams();

    const topRef = useRef<any>();

    const [selectedTask, setSelectedTask] = React.useState<any>(null);
    const [drawingImage, setDrawingImage] = React.useState<any>(null);
    const [progress, setProgress] = React.useState<number[]>([]);
    const [selectedItem, setSelectedItem] = React.useState<number>(0);
    const [adjustment, setAdjusts] = React.useState<any>({ text: "" });
    const [loadingData, setLoadingData] = React.useState<boolean>(false);
    const [loadingDownload, setLoadingDownload] = React.useState<boolean>(false);
    const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
    const [loadingApproveTask, setLoadingApproveTask] = React.useState<{ [key: string]: boolean }>({});
    const [loadingChangeApprove, setLoadingApprove] = React.useState<boolean>(false);
    const [loadingAdjustment, setLoadingAdjustment] = React.useState<boolean>(false);
    const [openAdjustment, setOpenAdjustment] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any>({ tasks: [] });
    const [updatingDescription, setUpdatingDescription] = React.useState<string>("");
    const [isUpdatingDescription, setIsUpdatingDescription] = React.useState<boolean>(false);
    const [customer, setCustomer] = React.useState<any>(null);
    const [flowIndex, setFlowIndex] = React.useState<any>(0);
    const [allowedToChange, setAllowedToChange] = React.useState<any>(null);
    const [status, setStatus] = React.useState<any>(null);

    const isMobile = useMediaQuery('(max-width: 1200px)');
    const recorder = useRecorder({ company: data?.company?._id ?? data?.company });

    const media = { ...getMedia(selectedTask?.destination) };
    const { Icon: MediaIcon = FaTasks } = media;

    useEffect(() => {
        const phoneReplace = / |\(|\)|\-|\.|\_/g
        const inFlow = (data?.customer?.flow ?? []).findIndex(f => (f.approvers ?? []).some(a =>
            (a.email && [props?.approver?.email, userData?.user?.email].filter(nn => nn).includes(a.email)) ||
            (a.phone && [props?.approver?.phone, userData?.user?.phone].filter(nn => nn).map(p => p.replace(phoneReplace, "")).includes(a.phone.replace(phoneReplace, "")))
        ));
        const jobApproved = status?.key === "approved"
        const atc = (
            !loadingData &&
            !jobApproved &&
            !userData?.settings?.approve_block &&
            !(!status || status?.key === "draft") &&
            (
                role ||
                (data?.customer?.flow ?? []).length <= 1 ||
                inFlow === flowIndex
            )
        );
        setAllowedToChange(atc);
    }, [loadingData, userData, role, status, data, props, flowIndex]);

    useEffect(() => {
        setStatus((data?.job_status || []).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)[0]?.status);
    }, [data]);

    useEffect(() => {
        let fi = (data?.flow ?? []).length;
        let maxIndex = (data?.customer?.flow ?? []).length - 1;
        if (fi > maxIndex) fi = maxIndex;
        setFlowIndex(fi)
    }, [data]);

    useEffect(() => {
        let fi = (data?.flow ?? []).length;
        let maxIndex = (data?.customer?.flow ?? []).length - 1;
        if (fi > maxIndex) fi = maxIndex;
        setFlowIndex(fi)
    }, [data]);

    const loadData = () => {
        setLoadingData(true)
        GET_JOB_DETAILS(jobId)
            .then((dt) => {
                setLoadingData(false)
                setData({ ...dt })
                const notApprovedIndex = (dt.tasks ?? []).findIndex(t => getTaskStatus(t)?.key !== "approved");
                setSelectedTask(dt?.tasks[notApprovedIndex > -1 ? notApprovedIndex : 0])
                if (!dt.active || dt.archived) {
                    showNotification({ message: "Esse item não está mais ativo.", color: "red" });
                    navigate("/");
                }
            })
            .catch(err => {
                setLoadingData(false)
                showNotification({ title: "Ops", message: err.message, color: 'red' })
            })
    }

    const markView = () => {
        if (!role && props.approver && jobId) {
            MARK_JOB_VIEW(jobId, props.approver)
                .then((res) => {
                    setCustomer(res)
                })
                .catch(err => {
                    showNotification({ title: "Ops", message: err.message, color: 'red' })
                })
        }
    }

    const approveTask = (taskId) => {
        let isLast = !(data?.tasks || []).some(t => t._id !== taskId && getTaskStatus(t)?.key !== "approved")
        setLoadingApproveTask(a => ({ ...a, [`${taskId}`]: true }))
        APPROVE_TASK(taskId, props.approver)
            .then(() => {
                if (isLast) approveJob();
                setLoadingApproveTask(a => ({ ...a, [`${taskId}`]: false }))
                setSelectedTask((t) => {
                    let notApproved = (data?.tasks || []).find(t => t._id !== taskId && getTaskStatus(t)?.key !== "approved")
                    let i = data.tasks.findIndex(t => t._id === notApproved?._id);
                    if (i > -1) { return data.tasks[i]; }
                    return t;
                })
                setSelectedItem(0)
                showNotification({ message: "Item aprovado!", color: 'green' })
                loadData();
            })
            .catch(err => {
                setLoadingApproveTask(a => ({ ...a, [`${taskId}`]: false }))
                showNotification({ title: "Ops", message: err.message, color: 'red' })
            })
    }

    const approveJob = () => {
        setLoadingApprove(true)
        APPROVE_JOB(jobId, props.approver)
            .then(() => {
                setLoadingApprove(false)
                loadData();
                showNotification({ message: "Job aprovado!", color: 'green' })
            })
            .catch(err => {
                setLoadingApprove(false)
                showNotification({ title: "Ops", message: err.message, color: 'red' })
            })
    }

    const handleSaveDescription = () => {
        setSelectedTask(t => ({ ...t, description: updatingDescription }))
        setIsUpdatingDescription(false);
        PUBLIC_SAVE_TASK(selectedTask?._id, props.approver, { description: updatingDescription })
            .then(() => {
                showNotification({ message: "Descrição atualizada", color: 'green' })
            })
            .catch(err => {
                setLoadingApprove(false)
                showNotification({ title: "Ops", message: err.message, color: 'red' })
            })
    }

    const adjustmentTask = async (taskId) => {
        setLoadingAdjustment(true);
        try {
            let audio;
            if (recorder.audio) {
                const { url } = await recorder.saveAudio();
                audio = url;
            }
            ADJUSTMENT_TASK(taskId, {
                ...adjustment,
                approver: props.approver,
                audio,
            })
                .then(() => {
                    setLoadingAdjustment(false)
                    loadData();
                    recorder.clear();
                    setOpenAdjustment(false);
                    setAdjusts("");
                    showNotification({ message: "Solicitação de ajuste enviada! Você pode navegar nos demais itens enquanto o time verifica sua solicitação.", color: 'green' })
                })
                .catch(err => {
                    setLoadingAdjustment(false)
                    showNotification({ title: "Ops", message: err.message, color: 'red' })
                })
        } catch (err) {
            setLoadingAdjustment(false)
            showNotification({ title: "Ops", message: err.message, color: 'red' })
        }
    }

    const getTaskStatus = (task) => {
        if (!task) return null;
        const status = (task.job_status || []).filter(js => js.index === flowIndex).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)[0]?.status;
        return status
    }

    useEffect(() => {
        if (jobId !== "novo") { loadData() }
    }, [jobId])

    useEffect(() => {
        if ((data?.tasks || []).length > 0 && !selectedTask) {
            setSelectedTask(data.tasks[0])
        }
    }, [data, selectedTask])

    useEffect(() => {
        setProgress([])
    }, [selectedTask])

    useEffect(() => {
        if (topRef.current) topRef.current.scrollIntoView({ behavior: "smooth" });
    }, [topRef.current, selectedTask?._id])

    useEffect(markView, [role, jobId, props.approver]);
    useEffect(() => {
        setUpdatingDescription(selectedTask?.description)
    }, [selectedTask?.description]);

    return <>
        <div style={{ position: 'relative' }} ref={topRef}>
            <Paper p="md" shadow='xs' mb="md">
                <Group>
                    <Title style={{ flex: 1 }} order={4}>{data?.name}</Title>
                    {role && props.update && <Menu position='bottom'>
                        <Menu.Target>
                            <Tooltip label="Compartilhar">
                                <ActionIcon variant='light' color="gray" size="md"><FaShare /></ActionIcon>
                            </Tooltip>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Label>Selecione o aprovador</Menu.Label>
                            {(data?.share_links || []).map(link => <Menu.Item
                                onClick={() => {
                                    navigator.clipboard.writeText(`https://${window.location.host}/${link._id}`);
                                    showNotification({ message: "O link de compartilhamento foi copiado para área de transferência", color: "blue" });
                                }}
                            >{link?.props?.approver?.name}</Menu.Item>)}
                        </Menu.Dropdown>
                    </Menu>}
                    {role && props.update && <Tooltip label="Editar">
                        <ActionIcon
                            variant='light'
                            color="gray"
                            size="md"
                            onClick={props.update}
                        ><FaEdit /></ActionIcon>
                    </Tooltip>}
                </Group>
                <Group pt="md" pb="md">
                    {(data.tasks || []).map((task, i) => {
                        const status = getTaskStatus(task);
                        const color = {
                            "approved": "green",
                            "adjust": "yellow",
                        }[status?.key] ?? "gray";
                        const title = {
                            "approved": "Aprovado",
                            "adjust": "Em Ajuste",
                        }[status?.key] ?? "Aguardando Aprovação";
                        const Icon = {
                            "approved": FaCheck,
                            "adjust": FaExclamation,
                        }[status?.key] ?? FaClock;

                        return <Tooltip label={title}>
                            <Button
                                onClick={() => {
                                    setSelectedTask(task);
                                    setSelectedItem(0);
                                }}
                                color={color}
                                style={{ position: 'relative' }}
                                size="sm"
                                leftIcon={<Icon />}
                                variant={task._id === selectedTask?._id ? "filled" : "outline"}
                            >
                                <Text>{i + 1}</Text>
                            </Button>
                        </Tooltip>
                    })}
                </Group>
            </Paper>
            <Grid>
                {(data?.customer?.flow ?? []).length > 1 && <Grid.Col xs={12} md={2}>
                    <Paper p="xl" shadow='xs'>
                        {(data?.customer?.flow ?? []).map((flow, i) => (
                            <Button
                                styles={{ root: { whiteSpace: 'normal', height: 'auto', paddingBottom: 10, paddingTop: 10 } }}
                                size="sm"
                                ta="center"
                                fullWidth
                                mb="md"
                                color={flowIndex > i ? "green" : flowIndex === i ? "blue" : "gray"}
                                variant={flowIndex >= i ? "filled" : "outline"}
                                disabled={flowIndex !== i}
                            >
                                <Group style={{ flexDirection: 'column' }} spacing={0}>
                                    <Text size="xs">Nível {i + 1}</Text>
                                    <Text size="lg">{flow.title ?? ""}</Text>
                                </Group>
                            </Button>
                        ))}
                    </Paper>
                </Grid.Col>}
                <Grid.Col xs={12} md={(data?.customer?.flow ?? []).length > 1 ? 7 : 9}>
                    <Paper shadow='xs'>
                        <Box p="md">
                            <Group>
                                <Avatar color={media?.color} variant='filled'>
                                    <MediaIcon />
                                </Avatar>
                                <Box style={{ flex: 1 }}>
                                    <Title order={4}>{selectedTask?.name}</Title>
                                </Box>
                                {(selectedTask?.items ?? [])[selectedItem] && <ActionIcon
                                    color='gray'
                                    variant='light'
                                    size="lg"
                                    onClick={() => {
                                        const item = (selectedTask?.items ?? [])[selectedItem];
                                        if (item) {
                                            window.open(item.value, "_blank");
                                        }
                                    }}
                                >
                                    <FaEye />
                                </ActionIcon>}
                                {(selectedTask?.items ?? []).length > 0 && <ActionIcon
                                    color='gray'
                                    variant='light'
                                    size="lg"
                                    loading={loadingDownload}
                                    onClick={async () => {
                                        setLoadingDownload(true)
                                        try {
                                            const buffer = Buffer.from(await DOWNLOAD_TASK(selectedTask?._id));

                                            const byteNumbers = new Array(buffer.length);
                                            for (let i = 0; i < buffer.length; i++) { byteNumbers[i] = buffer[i]; }
                                            const byteArray = new Uint8Array(byteNumbers);

                                            const blob = new Blob([byteArray], { type: "application/csv" });

                                            const url = URL.createObjectURL(blob);;
                                            const a = document.createElement('a');
                                            a.href = url;
                                            a.download = `task_${selectedTask?._id}.zip`;
                                            a.click();
                                            URL.revokeObjectURL(url);
                                        } catch (err) {
                                            showNotification({ message: err.message, color: "red" });
                                        } finally {
                                            setLoadingDownload(false)
                                        }
                                        // downloadFiles(
                                        //     (selectedTask?.items ?? [])
                                        //     .filter(item => item.value && !item.origin && ["video", "image", "audio", "file"].includes(item.type))
                                        //     .map((item, i) => ({
                                        //         url: (item.origin === "drive" && item.externalId)
                                        //             ? `https://www.googleapis.com/drive/v3/files/${item.externalId}?alt=media&key=${process.env.REACT_APP_GOOGLE_DRIVE_API_KEY}`
                                        //             : item.value,
                                        //         directory: `${selectedTask?._id ?? "unselected"}`,
                                        //         name: `${i + 1}`,
                                        //     }))
                                        //     , `task_${selectedTask?._id}`
                                        // )
                                    }}
                                >
                                    <FaFileDownload />
                                </ActionIcon>}
                            </Group>

                            {selectedTask?.publish_date && <Text mt="md">Horário para publicar: {getExtenseDatetime(selectedTask?.publish_date, true)}</Text>}
                            {selectedTask?.allow_schedule && selectedTask?.publish_account_title && <Text mt="md">Conta agendada: {selectedTask?.publish_account_title}</Text>}
                        </Box>
                        {(selectedTask?.items ?? []).length > 0 && <Box style={{ position: 'relative', background: '#000000' }} mb="md">
                            <Group p="md" style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 999 }}>
                                {
                                    (selectedTask?.items ?? []).length === 0
                                        ? <div style={{ flex: 1 }}></div>
                                        : (selectedTask?.items ?? []).map((item, i) => (
                                            <Progress
                                                style={{ flex: 1, cursor: 'pointer' }}
                                                value={progress[i] ?? 0}
                                                color="lime"
                                                bg="gray"
                                                onClick={() => setSelectedItem(i)}
                                            />
                                        ))
                                }
                            </Group>
                            {selectedItem > 0 && <Group style={{
                                bottom: 0,
                                top: 0,
                                left: 0,
                                padding: 12,
                                position: 'absolute',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                zIndex: 10,
                            }}>
                                {
                                    ([
                                        {
                                            icon: <FaArrowLeft />,
                                            active: selectedItem > 0,
                                            onClick: () => setSelectedItem(i => i - 1)
                                        },
                                        // { icon: <MediaIcon />, color: media.color, variant: "filled" },
                                    ] as any[]).map(({ icon, variant = "light", onClick, color, active = true }) => (
                                        <ActionIcon
                                            size="xl"
                                            variant={variant}
                                            color={color}
                                            onClick={onClick}
                                            disabled={!active}
                                            style={{
                                                opacity: active ? 0.9 : 0.5,
                                                borderRadius: 50,
                                            }}
                                        >{icon}</ActionIcon>
                                    ))
                                }
                            </Group>}

                            {selectedItem < (selectedTask?.items || []).length - 1 && <Group style={{
                                bottom: 0,
                                top: 0,
                                right: 0,
                                padding: 12,
                                position: 'absolute',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                zIndex: 10,
                            }}>
                                {
                                    ([
                                        {
                                            icon: <FaArrowRight />,
                                            active: selectedItem < (selectedTask?.items || []).length - 1,
                                            onClick: () => setSelectedItem(i => i + 1)
                                        },
                                    ] as any[]).filter(nn => nn).map(({ icon, color, variant = "light", onClick, active = true }) => (
                                        <ActionIcon
                                            size="xl"
                                            variant={variant}
                                            color={color}
                                            onClick={onClick}
                                            disabled={!active}
                                            style={{
                                                opacity: active ? 0.9 : 0.5,
                                                borderRadius: 50,
                                            }}
                                        >{icon}</ActionIcon>
                                    ))
                                }
                            </Group>}
                            <Carousel
                                showThumbs={false}
                                onChange={(i) => setSelectedItem(i)}
                                selectedItem={selectedItem}
                                showArrows={false}
                                showStatus={false}
                                swipeScrollTolerance={0}
                                preventMovementUntilSwipeScrollTolerance
                            >
                                {(selectedTask?.items || []).map((item, itemIndex) => <ItemView
                                    item={item}
                                    height={window.innerHeight - 120}
                                    onProgress={p => setProgress(pg => selectedTask?.items.map((it, i) => i === itemIndex ? p : pg[i]))}
                                    active={itemIndex === selectedItem}
                                // onFinish={() => setSelectedItem(i => i >= (selectedTask?.items ?? []).length - 1 ? (selectedTask?.items ?? []).length - 1 : itemIndex + 1)}
                                />)}
                            </Carousel>
                        </Box>}
                        {/* <Group align='center' style={{justifyContent: 'center'}} pl="md" pr="md">
                        <ActionIcon onClick={() => setSelectedItem(i => i-1)} size="xl" variant='outline'
                            style={{visibility: selectedItem > 0 ? "visible" : "hidden"}}
                        ><FaArrowLeft/></ActionIcon>
                        <div style={{flex: 1, whiteSpace: 'nowrap', overflowX: 'auto', display: 'flex', flexDirection: 'row', padding: '20px 0'}}>
                            {
                                (selectedTask?.items || []).map((task, i) => <Box
                                    mr="md"
                                    onClick={() => setSelectedItem(i)}
                                    style={{ border: '1px solid #228be6', borderRadius: 5, cursor: 'pointer'}}>
                                        {(task.thumb || !task.type || task.type === "image")
                                            ? <Image  width={84} height={84} src={task.thumb ?? task.value} />
                                            : task.type === "video"
                                            ? <Paper shadow='xs'
                                                style={{
                                                    border: selectedItem === i ? '5px solid #2470ac' : '1px solid #2470ac',
                                                    height: 80,
                                                    width: 80,
                                                    background: 'black',
                                                }}
                                            ><video
                                                src={`${task.value}#t=0.1`}
                                                style={{
                                                    height: "100%",
                                                    cursor: 'pointer',
                                                    width: "100%",
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    objectFit: 'cover'
                                                }}
                                                playsInline
                                                preload='metadata' 
                                                muted
                                                autoPlay={false}
                                                controls={false}
                                            /></Paper>
                                        : <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: 84, height: 84}}>
                                            <Text color="lime" size="xs">{getTypeText(task.type)}</Text>
                                        </Box>}
                                </Box>)
                            }
                        </div>
                        <ActionIcon onClick={() => setSelectedItem(i => i+1)} size="xl" variant='outline'
                            style={{visibility: selectedItem < (selectedTask?.items || []).length - 1 ? "visible" : "hidden"}}
                        ><FaArrowRight/></ActionIcon>
                    </Group> */}
                        {selectedTask?.description && <div>
                            <Box style={{ position: 'relative', overflowY: 'auto', wordBreak: 'break-word', textAlign: 'justify', margin: '0' }} p="md">
                                <Group mb="md">
                                    <FaQuoteLeft color="#8a8a8a" />
                                    <Text size="lg" color="#8a8a8a">Legenda</Text>
                                </Group>

                                {selectedTask?.description.split('\n').map(str => <p>{markdown(str)}</p>)}

                                {props.approver && allowedToChange && !data?.customer?.update_block && <Tooltip label='Editar'>
                                    <ActionIcon
                                        size="lg"
                                        onClick={() => setIsUpdatingDescription(true)}
                                        style={{ position: 'absolute', top: 10, right: 10 }} color="lime"><FaEdit /></ActionIcon>
                                </Tooltip>}
                            </Box>
                        </div>}

                        {allowedToChange && <Group mt="md" p="md" style={{ justifyContent: 'space-between' }}>
                            <Button
                                onClick={() => setOpenAdjustment(true)}
                                variant="outline"
                                size="lg"
                                color="blue"
                                style={isMobile ? { flex: 1 } : {}}
                                disabled={loadingSave || loadingChangeApprove}
                            >Solicitar ajuste</Button>
                            {getTaskStatus(selectedTask)?.key !== "approved" && <Button
                                variant='filled'
                                color="green"
                                disabled={!role && (loadingAdjustment || loadingApproveTask[`${selectedTask?._id}`] || ![null, undefined, "to-approve"].includes(getTaskStatus(selectedTask)?.key))}
                                loading={loadingChangeApprove || loadingApproveTask[`${selectedTask?._id}`]}
                                size="lg"
                                style={isMobile ? { flex: 1 } : {}}
                                onClick={() => approveTask(selectedTask._id)}
                            >{getTaskStatus(selectedTask)?.key === "approved" ? "Aprovado" : "Aprovar item"}
                            </Button>}
                            {(data.tasks || []).every(t => getTaskStatus(t)?.key === "approved") &&
                                <Button
                                    variant='filled'
                                    color="green"
                                    size="sm"
                                    style={isMobile ? { flex: 1 } : {}}
                                    disabled={!role && (status?.key === "approved" || loadingAdjustment)}
                                    loading={loadingChangeApprove}
                                    onClick={approveJob}
                                >Aprovar Job</Button>}
                        </Group>}
                    </Paper>
                </Grid.Col>
                <Grid.Col xs={12} md={3}>
                    <JobHistory data={{ ...selectedTask, views: data.views }} />
                </Grid.Col>
            </Grid>
        </div>

        <Modal
            opened={drawingImage}
            onClose={() => setDrawingImage(null)}
            fullScreen
        >
            <RenderImage
                src={drawingImage?.source}
            // draw
            />
        </Modal>

        <Modal opened={isUpdatingDescription} onClose={() => setIsUpdatingDescription(false)}>
            <InputField
                name="description"
                value={updatingDescription}
                fieldType={InputFieldTypes.TEXTAREA}
                mb="lg"
                onChange={({ description }) => setUpdatingDescription(description)}
            />
            <Button fullWidth variant='outline' color="lime" onClick={handleSaveDescription}>Salvar</Button>
        </Modal>

        <Modal
            size="lg"
            opened={openAdjustment}
            title={`Solicitar ajuste do item "${selectedTask?.name}"`}
            onClose={() => setOpenAdjustment(false)}
        >
            {adjustment.drawing
                ? <Carousel
                    showThumbs={false}
                    // selectedItem={selectedItem}
                    showArrows={true}
                    showStatus={false}
                    // dynamicHeight
                    showIndicators={false}
                // swipeScrollTolerance={400}

                // preventMovementUntilSwipeScrollTolerance
                >
                    {(selectedTask?.items ?? [])
                        .filter(i => i.type === "image" && i.origin !== "drive")
                        .map((item, index) => (
                            <CanvasDrawer
                                image={item.value}
                                onClose={() => {
                                    setAdjusts(a => ({ ...a, drawing: false }))
                                }}
                                draw={{ ...adjustment?.draws }[item.value]}
                                onDraw={draw => setAdjusts(a => ({ ...a, draws: { ...a?.draws, [item.value]: draw } }))}
                            />
                        ))}
                </Carousel>
                : <>
                    <InputField
                        name="text"
                        mt="md"
                        mb="md"
                        placeholder='Digite o texto aqui...'
                        value={adjustment.text}
                        onChange={({ text }) => setAdjusts(a => ({ ...a, text }))}
                        fieldType={InputFieldTypes.TEXTAREA}
                    />
                    {(recorder.audio || recorder.recording) && (
                        <Group position="center" style={{ flex: 1 }}>
                            {!loadingAdjustment && !recorder.recording && (
                                <ActionIcon onClick={recorder.clear} color="gray">
                                    <FaTrash />
                                </ActionIcon>
                            )}
                            <Group style={{ flex: 1 }}>
                                {!recorder.recording && (
                                    <audio
                                        src={recorder.audio}
                                        controls
                                        controlsList="nodownload"
                                        style={{ width: "100%" }}
                                    />
                                )}
                            </Group>
                            <Title order={4} color="gray">
                                {formatMilliseconds(recorder.timeCounter)}
                            </Title>
                        </Group>
                    )
                    }
                    <Group mt="xl">
                        {recorder.recording ? (
                            <ActionIcon
                                size="lg"
                                disabled={loadingAdjustment}
                                variant="filled"
                                onClick={recorder.stopRecording}
                                color="red"
                            >
                                <FaMicrophone />
                            </ActionIcon>
                        ) : (
                            <ActionIcon
                                size="lg"
                                disabled={loadingAdjustment}
                                variant="outline"
                                color="gray"
                                onClick={recorder.startRecording}
                            >
                                <FaMicrophone />
                            </ActionIcon>
                        )}
                        {(selectedTask?.items ?? [])[selectedItem ?? 0]?.type === "image" && <ActionIcon
                            size="lg"
                            variant='outline'
                            disabled={loadingAdjustment}
                            onClick={() => setAdjusts(a => ({ ...a, drawing: true }))}
                        ><FaDrawPolygon /></ActionIcon>}
                        <div style={{ flex: 1 }}></div>
                        <Button
                            variant='filled'
                            color="yellow"
                            disabled={recorder.recording || adjustment.drawing}
                            loading={loadingAdjustment}
                            onClick={() => adjustmentTask(selectedTask?._id)}
                        >Solicitar ajuste</Button>
                    </Group>
                </>}
        </Modal>
    </>
}

const ItemView = ({ item, height: initialHeight, onFinish = null, onProgress, active }) => {
    const { muted, setMuted, expandImage } = useApp();

    const videoRef = useRef<HTMLVideoElement>();
    const parentRef = useRef<any>();

    const [height, setHeight] = useState(0);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(10000);

    const toggleMute = () => {
        setMuted(false);
        // videoRef.current.muted = false;
    };

    useEffect(() => {
        if (onFinish && progress >= duration) onFinish();
        onProgress((progress / duration) * 100);
    }, [progress, duration]);

    useEffect(() => {
        setHeight(initialHeight);
    }, [item, initialHeight]);

    useEffect(() => {
        if (videoRef.current) {
            if (active) {
                videoRef.current.muted = muted;
                videoRef.current.currentTime = 0;
                videoRef.current.play()
            } else {
                videoRef.current.pause();
            }
        }
    }, [muted, videoRef, active])

    useEffect(() => {
        if (active) {
            if (!item.origin || item.type !== "video") {
                setDuration(10000);
                setProgress(0)
                // const interval = setInterval(() => { setProgress(p => p += 100) }, 100);
                // return () => {
                //     clearInterval(interval);
                // }
            }
        } else {

        }
    }, [videoRef, active, item]);

    const handleTimeUpdate = () => {
        if (videoRef.current) {
            setProgress(videoRef.current.currentTime * 1000);
        }
    };

    const handleLoadedMetadata = () => {
        const video = videoRef.current;
        if (!video) return;
        setDuration(video.duration * 1000);

        const proportion = videoRef.current.videoHeight / videoRef.current.videoWidth;
        let itemHeight = parentRef.current.offsetWidth * proportion;
        if (itemHeight > window.innerHeight) itemHeight = window.innerHeight;
        setHeight(itemHeight);
    };

    const handleImageSize = ({ height, width }) => {
        const proportion = height / width;
        let itemHeight = parentRef.current.offsetWidth * proportion;
        if (itemHeight > window.innerHeight) itemHeight = window.innerHeight;
        setHeight(itemHeight);
    }

    if (!active) return null;
    return <div
        style={{ height, width: '100%', position: 'relative' }}
        ref={parentRef}
    >
        {item.type === "video" && !["drive"].includes(item.origin) && muted && <Button
            leftIcon={<FaVolumeMute />}
            style={{ position: 'absolute', top: 80, left: 80, zIndex: 900 }}
            onClick={toggleMute} variant='light' color="gray"
        >Ouvir Áudio</Button>}
        {
            (item.type === "text") &&
            <Box style={{ textAlign: 'left', border: 10, height: height * 0.8, overflowY: 'auto', background: "#F4F4F4", margin: `${height * 0.1}px 40px 0` }} p="lg">
                {markdown(item.value ?? "")}
            </Box>
        }
        {
            // (item.type === "video" && item.origin !== "drive")
            // ? <ReactPlayer
            //     url={item.value}
            //     playsinline
            //     playing={active}
            //     loop
            //     onLoadedMetadata={handleLoadedMetadata} 
            //     volume={1}
            //     muted={muted}
            //     height={height}
            //     width={"100%"}
            //     playIcon={<ActionIcon color="lime" variant='filled'><FaPlay/></ActionIcon>}
            // />
            // :
            (item.origin === "youtube" || (item.origin === "drive" && ["video", "audio", "image"].includes(item.type)))
                ? <iframe
                    width="100%"
                    height="100%"
                    style={{ objectFit: 'contain' }}
                    src={(item.origin === "drive" && item.externalId) ? `https://drive.google.com/file/d/${item.externalId}/preview` : item.value}>
                </iframe>
                : (!item.type || item.type === "image")
                    ? <RenderImage onImageSize={handleImageSize} src={item.value} height={height} />
                    : <>
                        {
                            ["file", "document"].includes(item.type) &&
                            <Box
                                ta="center"
                                p="md"
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}
                                onClick={() => {
                                    item.origin === "drive"
                                        ? expandImage(
                                            (item.externalId ? `https://drive.google.com/file/d/${item.externalId}/preview` : item.value),
                                            { drive: true }
                                        )
                                        : window.open(item.value, "_blank")
                                }}
                            >
                                <Button size="xl" color="lime" leftIcon={<FaEye />}>Abrir Arquivo</Button>
                            </Box>
                        }
                        {
                            (item.type === "video") &&
                            <video
                                src={`${item.value}#t=0.1`}
                                playsInline
                                ref={videoRef}
                                onClick={() => {
                                    if (active) {
                                        if (videoRef.current.paused) videoRef.current.play()
                                        else videoRef.current.pause()
                                    }
                                }}
                                onLoadedMetadata={handleLoadedMetadata}
                                onTimeUpdate={handleTimeUpdate}
                                style={{ height, width: '100%', cursor: "pointer" }}
                                muted
                                loop
                                autoPlay={false}
                                preload='metadata'
                            />
                        }
                        {
                            (item.type === "audio") && (
                                <div style={{ padding: 40 }}>
                                    <audio src={item.value} controls style={{ width: '100%' }} />
                                </div>
                            )
                        }
                    </>
        }
    </div>
}