import { useEffect, useRef, useState } from 'react';
import { SAVE_OAUTH } from '../../services/oauths';
import { showNotification } from '@mantine/notifications';
import CryptoJS from 'crypto-js'
import { Buffer } from 'buffer';
import pkceChallenge from 'pkce-challenge';

export enum OAUTH {
    FACEBOOK = "facebook",
    INSTAGRAM = "instagram",
    TWITTER = "twitter",
    PINTEREST = "pinterest",
    LINKEDIN = "linkedin",
    TIKTOK = "tiktok",
    YOUTUBE = "youtube",
    DRIVE = "drive",
    CANVA = "canva",
}

const useOauth = () => {
    const [externalPopup, setExternalPopup] = useState<any>(null);
    const [funcs, setFuncs] = useState<any>(null);
    const [origin, setOrigin] = useState<OAUTH | null>(null);
    const [done, setDone] = useState<boolean>(false);

    const onCode = (code) => {
        SAVE_OAUTH({ ...funcs?.options, origin, data: { ...funcs?.options?.data, code, code_verifier: funcs?.codeVerifier } })
        .then((res) => {
            funcs.onConnect && funcs.onConnect(res);
            showNotification({ message: `${origin} conectado`.toUpperCase(), color: 'green' });
        })
        .catch(err => {
            showNotification({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    const handleLogin = async (origin: OAUTH, onConnect, options = null) => {
        clear();
        setDone(false);
        setOrigin(origin);
        if(externalPopup){
            externalPopup.close();
            setExternalPopup(null);
        }
        // const redirectUri = `${window.location.origin}${`/oauth/${origin}`}`;
        const redirectUri = `https://app.aprova.ai${`/oauth/${origin}`}`;
        const width = 600;
        const height = 600;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;
        const { code_verifier: codeVerifier, code_challenge: codeChallenge } = await pkceChallenge();

        let scope = {
            [`${OAUTH.TWITTER}`]: 'tweet.write tweet.read users.read follows.read offline.access',
            [`${OAUTH.INSTAGRAM}`]: 'business_management,instagram_basic,instagram_content_publish',
            // [`${OAUTH.INSTAGRAM}`]: 'instagram_manage_insights,instagram_manage_comments,instagram_basic,instagram_content_publish',
            [`${OAUTH.FACEBOOK}`]: 'business_management,pages_manage_posts,pages_show_list',
            [`${OAUTH.PINTEREST}`]: 'user_accounts:read,pins:read,pins:read_secret,pins:write,pins:write_secret,boards:read,boards:write',
            [`${OAUTH.LINKEDIN}`]: 'openid profile email w_member_social',
            [`${OAUTH.YOUTUBE}`]: 'https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
            // [`${OAUTH.YOUTUBE}`]: 'https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
            [`${OAUTH.DRIVE}`]: 'https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
            [`${OAUTH.TIKTOK}`]: 'user.info.basic,user.info.profile,video.list,video.upload,video.publish',
            [`${OAUTH.CANVA}`]: 'asset:read asset:write profile:read design:content:read design:content:write design:meta:read',
        }[`${origin}`];
        let authURL = {
            [`${OAUTH.TWITTER}`]: `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_TWITTER_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${scope}&state=0-rsJAIDgALlYWs0SDQNIUWwzniGEGFfHy-OpbugHmw%3D&code_challenge=challenge&code_challenge_method=plain`,
            [`${OAUTH.INSTAGRAM}`]: `https://www.facebook.com/v20.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`,
            // [`${OAUTH.INSTAGRAM}`]: `https://www.instagram.com/oauth/authorize?client_id=${process.env.REACT_APP_INSTAGRAM_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`,
            [`${OAUTH.FACEBOOK}`]: `https://www.facebook.com/v20.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`,
            [`${OAUTH.PINTEREST}`]: `https://www.pinterest.com/oauth/?client_id=${process.env.REACT_APP_PINTEREST_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}`,
            [`${OAUTH.LINKEDIN}`]: `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&scope=${scope}&redirect_uri=${redirectUri}`,
            [`${OAUTH.YOUTUBE}`]: `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_YOUTUBE_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&access_type=offline&include_granted_scopes=false&prompt=consent`,
            [`${OAUTH.DRIVE}`]: `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&access_type=offline&include_granted_scopes=false&prompt=consent`,
            [`${OAUTH.TIKTOK}`]: `https://www.tiktok.com/v2/auth/authorize?client_key=${process.env.REACT_APP_TIKTOK_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&code_challenge=${codeChallenge}&code_challenge_method=S256`,
            [`${OAUTH.CANVA}`]: `https://www.canva.com/api/oauth/authorize?code_challenge_method=s256&response_type=code&client_id=${process.env.REACT_APP_CANVA_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${scope}&code_challenge=${codeChallenge}`,
        }[`${origin}`];
        
        const authWindow = window.open(
            authURL,
            "_blank"
            // 'Login X',
            // `width=${width},height=${height},left=${left},top=${top}`
        );

        setExternalPopup(authWindow);
        setFuncs({ codeChallenge, codeVerifier, onConnect, options });
    };

    const clear = () => {
        localStorage.removeItem(`${origin}_oauth_code`);
        localStorage.removeItem(`${origin}_oauth_error`);
        setDone(true);
    }

    useEffect(() => {
        if (!externalPopup || done) { return; }
    
        const timer = setInterval(() => {
            if (!externalPopup) {
                timer && clearInterval(timer);
                return;
            }
            try{
                const code = localStorage.getItem(`${origin}_oauth_code`);
                const error = localStorage.getItem(`${origin}_oauth_error`);
                if (code) {
                    console.log(`The popup URL has URL code param = ${code}`);
                    clear();
                    onCode(code);
                }else if(error){
                    clear();
                    showNotification({ message: `Falha ao conectar ao ${origin} - ${error}`, color: "red" });
                }
            }catch(err){ console.log(err) }
        }, 500);
        
        return () => { clearInterval(timer) };
    }, [externalPopup, done, funcs, origin])

    return {
        connect: handleLogin
    };
};

export default useOauth;
