import { showNotification } from '@mantine/notifications';
import axios from 'axios';
import { Buffer } from 'buffer';
import { useEffect, useState } from 'react';
import { useApp } from '../contexts/app.context';
import { FILE_UPLOADED, GENERATE_SIGN_UPLOAD_FILE, GET_FILE, UPLOAD_CANVA_FILE, UPLOAD_DRIVE_FILE } from '../services/files';

export const useFileUploader = ({ file, onFileUploaded }) => {
    const [error, setError] = useState<string>(null);
    const [fileSize, setFileSize] = useState<number>(0);
    const [serverProgress, setServerProgress] = useState(0);
    const [awsProgress, setAwsProgress] = useState(0);
    const [progress, setProgress] = useState(0);
    const [fileId, setFileId] = useState(null);
    const [filename, setFilename] = useState("Novo arquivo");
    const [fileType, setFileType] = useState("image");

    const { socket } = useApp();

    const getFileType = (type) => {
        type = type ?? "";
        let fieldType = "document";
        if (type.includes("image")) fieldType = "image";
        if (type.includes("video")) fieldType = "video";
        if (type.includes("audio")) fieldType = "audio";
        return fieldType;
    }

    const signUpload = (file, params: {
        size,
        filename,
        contentType,
        mimetype,
    }) => {
        GENERATE_SIGN_UPLOAD_FILE(params)
            .then(res => {
                axios.put(res.signedUrl, file, {
                    headers: { 'Content-Type': file.type },
                    onUploadProgress: (progressEvent) => {
                        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                        if (totalLength !== null) {
                            setServerProgress(Math.round((progressEvent.loaded * 100) / totalLength));
                            setAwsProgress(Math.round((progressEvent.loaded * 100) / totalLength));
                        }
                    },
                })
                    .then(() => {
                        onFileUploaded({ url: res.generatedUrl, type: getFileType(params.contentType) })
                        FILE_UPLOADED(res._id, { url: res.generatedUrl })
                            .then(() => { })
                            .catch(err => {
                                showNotification({ message: err.message, color: "red" })
                            })
                    })
                    .catch(err => {
                        setError(err.message);
                        showNotification({ message: err.message, color: "red" })
                    })
            })
            .catch(err => {
                setError(err.message);
                showNotification({ title: "Falha ao salvar arquivo", message: err.message, color: 'red' })
            })
    }

    const handleInsertFile = async (file) => {
        try {
            setFilename(file.name);
            console.log(file);
            let fieldType = getFileType(file.type);
            setFileType(fieldType);
            setFileSize(file.size);
            if (file.size > 512000000) throw new Error("Arquivo muito grande, máximo 512GB");

            if (fieldType === "image") {
                await new Promise((resolve, reject) => {
                    var fr = new FileReader();
                    fr.onload = function () {
                        var img: any = new Image();
                        img.onload = function () {
                            if (img.width > 8500 || img.height > 8500) reject(new Error(`Imagem com resoluções muito grandes - ${img.width} x ${img.height}px`));
                            else resolve(true);
                        };
                        img.src = fr.result;
                    };
                    fr.readAsDataURL(file);
                })
            }

            signUpload(file, {
                size: file.size,
                filename: file.name,
                contentType: file.type,
                mimetype: file.type,
            })
        } catch (err) {
            setError(err.message);
            showNotification({ message: err.message, color: "red" })
        }
    }

    const handleInsertDriveV2 = async () => {
        console.log("handleInsertDrive", file);

        try {
            const { data: metadata } = await axios.get(`https://www.googleapis.com/drive/v3/files/${file?.data?.id}`, {
                headers: {
                    Authorization: `Bearer ${file?.auth?.oauthAccessToken}`,
                },
                params: {
                    fields: 'name, size, mimeType, capabilities, exportLinks',
                    supportsAllDrives: true,
                    includeItemsFromAllDrives: true,
                },
            })
            if (!metadata.capabilities.canDownload) throw new Error("Você não tem acesso para baixar esse arquivo.");
            if (metadata.size > 512000000) throw new Error("Arquivo muito grande, máximo 512GB");
            let exportLink;
            for (let link in { ...metadata.exportLinks }) {
                if (!exportLink) exportLink = metadata.exportLinks[link];
            }
            const { data: driveRes } = await axios({
                url:
                    exportLink
                        ? exportLink
                        : `https://www.googleapis.com/drive/v3/files/${file?.data?.id}?alt=media`,
                method: 'GET',
                responseType: 'arraybuffer',
                headers: {
                    Authorization: `Bearer ${file?.auth?.oauthAccessToken}`,
                },
                params: {
                    supportsAllDrives: true,
                    includeItemsFromAllDrives: true,
                }
            })
            const buffer = Buffer.from(driveRes, "binary");
            const contentType = metadata.mimeType;
            const size = parseInt(metadata.size);
            const filename = exportLink
                ? exportLink.split("exportFormat")[1].split("&")[0]
                : metadata.name;
            setServerProgress(100);
            setFileSize(size);
            setFilename(filename);
            setFileType(getFileType(contentType));
            signUpload(buffer, { size, filename, contentType, mimetype: contentType })
        } catch (err) {
            setError(err.message);
            showNotification({ title: "Falha ao importar arquivo", message: err.message, color: 'red' })
        }

    }

    const handleInsertDrive = async () => {
        console.log("handleInsertDrive", file);

        UPLOAD_DRIVE_FILE(
            { fileId: file?.data?.id, oauthId: file?.auth?.oauthId },
        )
            .then(res => {
                setServerProgress(100);
                setFileSize(res.size);
                setFilename(res.original_name);
                setFileId(res._id);
                setFileType(getFileType(res.type));
            })
            .catch(err => {
                setError(err.message)
                showNotification({ title: "Falha ao importar arquivo", message: err.message, color: 'red' })
            })
    }

    const handleInsertCanva = async () => {
        console.log("handleInsertCanva", file);

        UPLOAD_CANVA_FILE(
            { ...file?.data, oauthId: file?.auth?.oauthId },
        )
            .then(res => {
                setServerProgress(100);
                setFileSize(res.size);
                setFilename(res.original_name);
                setFileId(res._id);
                setFileType(getFileType(res.type));
            })
            .catch(err => {
                setError(err.message)
                showNotification({ title: "Falha ao importar arquivo", message: err.message, color: 'red' })
            })
    }

    useEffect(() => { setProgress((serverProgress * 0.4) + (awsProgress * 0.6)) }, [serverProgress, awsProgress]);


    useEffect(() => {
        if (fileId && ["drive", "canva"].includes(file.origin) && socket && (awsProgress ?? 0) < 100) {
            const func = (params) => {
                const { progress, url, error } = params;
                if (error) setError(error);
                if (url) {
                    onFileUploaded({ url, type: fileType });
                    setTimeout(() => { setAwsProgress(100) }, 800);
                } else {
                    if (progress) {
                        setTimeout(() => {
                            setAwsProgress(progress);
                        }, 800)
                    }
                }
            }
            const refreshInterval = setInterval(() => {
                GET_FILE(fileId)
                    .then(({ url }) => {
                        if (url) {
                            onFileUploaded({ url, type: fileType });
                            setTimeout(() => { setAwsProgress(100) }, 800);
                        }
                    })
                    .catch(ignored => { })
            }, 15000);
            const evName = `file_upload_${fileId}`;
            socket.on(evName, func)
            return () => {
                socket.off(evName, func);
                clearInterval(refreshInterval)
            }
        }
    }, [file?.origin, fileId, socket, fileType, awsProgress]);

    useEffect(() => {
        if (file.origin) {
            if (file.origin === "drive") handleInsertDriveV2();
            if (file.origin === "canva") handleInsertCanva();
        } else {
            handleInsertFile(file);
        }
    }, [file]);

    return {
        name: filename,
        type: fileType,
        progress,
        error,
        size: fileSize,
    };
}